import axios from 'axios'
import {BACKEND_ENDPOINT} from '../constants'

const SignContract = async (data) => {

    let response = await axios.post(`${BACKEND_ENDPOINT}/signed_contract`, data)
    
    if(response.status === 200)
    {
        response = response.data
        return response
    }
    else
    {
        return null
    }
}

export default SignContract
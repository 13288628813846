import React from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import DatePicker from 'react-datepicker'

import SignContract from '../../Actions/SignContract'
import {withRouter} from 'react-router-dom'
import {BACKEND_ENDPOINT} from '../../constants'

import logo from '../../Images/logo.svg'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import '../../Stylesheets/contract.css'
import "react-datepicker/dist/react-datepicker.css";

class Contract extends React.Component{
    constructor(props)
    {
        super(props)
        this.state = {
            email: "",
            full_name: "",
            date: '',
            checked: false,
            contract_data: {},
            disabled: true
        }
    }

    componentDidMount()
    {
        const { match: { params } } = this.props;
        if("contract_id" in params)
        {
            this.getContractData("_id", params.contract_id)
        }
        else if("contract_slug" in params)
        {
            this.getContractData("slug", params.contract_slug)
        }

        this.setState({
            date: Date.now()
        })

        console.log(this.state)
    }

    getContractData = async (param, value) => {
        try{
        let response = await axios.get(`${BACKEND_ENDPOINT}/contract?${param}=${value}`)
        console.log(response)
        if(response.status === 200)
        {
            response = response["data"]
            if(response.status === true && "data" in response)
            {
                let contract_data = response["data"]
    
                this.setState({contract_data: contract_data})
            }
        }
        } catch(error)
        {
            console.log("Error getting contract")
        }
        
        
    }


    handleSubmit = async (e) => {
        e.preventDefault()
        if(this.state.checked)
        {
            if(this.state.email && this.state.email !== "")
            {
                if(this.state.full_name && this.state.full_name !== "")
                {
                    const date = new Date(this.state.date).toLocaleDateString('en-US')

                    console.log({
                        contract_id: this.state.contract_data["_id"],
                        email_address: this.state.email,
                        full_name: this.state.full_name,
                        date
                    })

                    let response = await SignContract({
                        contract_id: this.state.contract_data["_id"],
                        email_address: this.state.email,
                        full_name: this.state.full_name,
                        date
                    })
                    if(response["status"] === true && "_id" in response["data"])
                    {
                        let data = {
                            "from": "info@naya.studio",
                            "to": ["vivek@naya.studio", "saad@naya.studio"],
                            "email": this.state.full_name,
                            "contract": this.state.contract_data["name"]
                        }
                        let response = await axios.post(`${BACKEND_ENDPOINT}/send_email`, data)
                        console.log(response)
                        this.props.history.push("/thank-you")
                    }
                    console.log(response)
                }
                else
                {
                    this.setState({errorMessage: "Please enter a valid full name"})
                }
            }
            else
            {
                this.setState({errorMessage: "Please enter a valid email"})
            }
        }
        else
        {
            this.setState({errorMessage: "Please check the box to below"})
        }
    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({errorMessage: null, [name]: value})
    }

    createMarkup = (htmlContent) => {
        console.log(htmlContent)
        return {__html: htmlContent};
      }

    renderContract = () => {
        let contract_render = <p>{"No contract under this ID"}</p>
        let contract_data = this.state.contract_data
        if(contract_data)
        {
            switch(contract_data["content_type"])
            {
                case "HTML":
                    contract_render = <Container>
                        <Row>
                            <Col xs={{span:12, offset:0}} lg={{span:12, offset:0}}>
                                <div className="center" dangerouslySetInnerHTML={this.createMarkup(contract_data["html_content"])}></div>
                            </Col>
                        </Row>
                        
                        </Container>
                    break
                default:
                    contract_render = <p>{"No contract under this ID"}</p>
            }
        }
        return contract_render
    }

    render()
    {
        let contract_render = this.renderContract()
        console.log(this.state)

        const disabled = !(this.state.checked && this.state.email && this.state.full_name && validateEmail(this.state.email))

        return (
            <Container id="contract">
            <Container className='logo-container'>
                <Image src={logo} height={75} />
                <Row>
                        <h1 style={{ "width": "100%", "textAlign": "center" }}> NAYA </h1>
                </Row>
            </Container>
            {contract_render}
            {this.state.contract_data && 
            <Container>
                <Row>
                <Col xs={{span:10, offset:1}} md={{span:8, offset:2}} lg={{span:8, offset:2}}>
                <Form onSubmit={this.handleSubmit}>
                    {this.state.errorMessage && <Alert>{this.state.errorMessage}</Alert>}
                    
                    <Form.Group>
                        <Form.Label>
                            Email Address
                        </Form.Label>
                        <Form.Control
                            name="email"
                            placeholder="Enter your email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Full Name
                        </Form.Label>
                        <Form.Control
                            name="full_name"
                            type="text"
                            placeholder="Enter your full name"
                            value={this.state.full_name}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Date
                        </Form.Label>
                        {/* <Form.Control
                            name="date"
                            type="date"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            placeholder={this.state.date}
                            value={this.state.date}
                            onChange={this.handleChange}
                        /> */}
                        <DatePicker 
                            selected={this.state.date}
                            onChange={date => this.setState({date: date})}
                        />
                    </Form.Group>
                    <Form.Group className='checked'>
                        <Form.Check
                            id='checked'
                            name="checked"
                            onChange={() => {this.setState({checked: !this.state.checked})}}
                            checked={this.state.checked}
                            required
                        />
                        <Form.Label htmlFor='checked' className='checked'>
                            I have read and understood the contract stated above.
                        </Form.Label>
                    </Form.Group>
                    <button className='submit' type={"submit"} disabled={disabled}>Sign Document</button>
                </Form>
                </Col>
                </Row>
            </Container>}
            </Container>
        )
    }

}

export default withRouter(Contract)

/**
 * 
 * @param {string} value email address to be validated
 * @returns {boolean} true if email is a valid email 
 */
export const validateEmail = (value) => {
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

    if (emailRegex.test(value)) {
        return true
    }

    return false
}
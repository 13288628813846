import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Contract from './Components/Contract/Contract'
import ThankYou from './Components/ThankYou/ThankYou';

function App() {
  return (
    <Router>
      <Switch>

      <Route path="/thank-you" component={ThankYou}>
        </Route>

        <Route path="/contract/:contract_id" component={Contract}>
        </Route>

        <Route path="/sign/:contract_slug" component={Contract}>
        </Route>

        <Route path="/">
          <h1>Welcome to Naya Contracts!</h1>
        </Route>
        
      </Switch>
    </Router>
  );
}

export default App;

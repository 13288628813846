import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import logo from '../../Images/logo.svg'

import '../../Stylesheets/thank_you.css'
import check from '../../Images/selected.svg'
class ThankYou extends React.Component
{

    render()
    {
        return (
            <Container id="thank_you">
                <Row>
                <Col xs={12} className='logo-container'>
                    <Image src={logo} height={75} />
                    <Row>
                        <h1 style={{ "width": "100%", "textAlign": "center" }}> NAYA </h1>
                    </Row>
                </Col>
                <Col xs={12} style={{"display":"flex", "justifyContent":"center"}}>
                    <img src={check} alt='check'></img>
                </Col>
                <Col xs={12}>
                    <p>Your signature has been recorded</p>
                </Col>
                </Row>
            </Container>
        )
    }
}

export default ThankYou